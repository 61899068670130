console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
let actualHour = new Date();
console.log("time",actualHour.getTime());

const homeTopSlides = {
  slides: [
    {
      caption: "Resorts For Everyone",
      link: "/deals/uk-offers/",
      img: "https://cdn.sandals.com/beaches/v12/images/home/top-slides/Beaches_WinterBlues_HpBanner_Desktop2.jpg",
      mobimg: "https://cdn.sandals.com/beaches/v12/images/home/top-slides/Beaches_WinterBlues_HpBanner_Mobile2.jpg",
      ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
      customizeLayout: false,
    },
    {
      caption: "All-New Beachfront Villas & Penthouse Collection",
      link: "/resorts/negril/",
      img: "https://cdn.sandals.com/beaches/v12/images/home/top-slides/Beaches_New-Accommodations-HP.jpg",
      mobimg: "https://cdn.sandals.com/beaches/v12/images/home/top-slides/Beaches_New-Accommodations-HP.jpg",
      ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
      customizeLayout: false,
    }
  ],
};

module.exports = homeTopSlides;
